import { createStyles, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import AddIcon from '../../assets/addIcon';
import DeleteIcon from '../../assets/deleteIcon';
import { Client, Commerce, RootState } from '../../store/config/types';
import styles from '../../styles/_variables.module.scss';
import { CustomSwitch } from '../CustomSwitch';

const useStyles = makeStyles(() =>
  createStyles({
    settingsContainer: {
      padding: '1.3125rem 2rem',
      marginBottom: '1.875rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      background: styles.white,
      boxShadow: styles.boxShadow,
      borderRadius: '4px',
      color: styles.slateBlue,
      '& span': {
        margin: 0,
      },
    },
  }),
);

interface ClientSettingProps {
  selectedClient: Client;
  handleBillingChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  createCommerce: () => void;
  updateCommerce: (commerce: Commerce) => void;
  beginDeleteCommerce: (commerce: Commerce) => void;
}

function ClientSettings({
  selectedClient,
  handleBillingChange,
  createCommerce,
  updateCommerce,
  beginDeleteCommerce,
}: ClientSettingProps) {
  const classes = useStyles();
  const commerces: Commerce[] | null = useSelector((state: RootState) => state.commerces.commerces);

  return (
    <>
      <div className={classes.settingsContainer}>
        <Typography>Habilitar facturación electrónica</Typography>
        <CustomSwitch
          checked={selectedClient!.billingEnabled}
          onChange={handleBillingChange}
          color="primary"
          name="checkedB"
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      </div>
      <Typography variant="h6">Medios de Pago</Typography>
      <Grid container spacing={2} className="commerces-list">
        <Grid item xs={12} sm={4} key="new">
          <div className="new-commerce" onClick={createCommerce}>
            <AddIcon />
            <Typography variant="subtitle2">Nuevo medio de pago</Typography>
          </div>
        </Grid>
        {commerces &&
          commerces.map((commerce, index) => {
            return (
              <Grid item xs={12} sm={4} key={index}>
                <div className="commerce" onClick={() => updateCommerce(commerce)}>
                  <div className="trash">
                    <span
                      onClick={(e: React.SyntheticEvent<any>) => {
                        e.stopPropagation();
                        beginDeleteCommerce(commerce);
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </div>
                  <img src={commerce.issuerImageUrl} alt="logo" />
                  <Divider orientation="horizontal" className="divider" />
                  <div className="number">
                    <Typography noWrap className="commerce-text">
                      {commerce.number ? commerce.number : commerce.issuerName}
                    </Typography>
                  </div>
                </div>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
}

export default ClientSettings;
